import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { carsPage } from 'src/data';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isMenuCollapsed = true;

  currentPosition: number = 0;
  limitPosition: number = 50;
  enableNavbarStyle: boolean = false;
  cars = carsPage;
  
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private activatedRoute: Router
  ) {
    this._document.addEventListener('scroll', this.onContentScrolled);
    this.activatedRoute.events.pipe(filter(x => x instanceof NavigationEnd))
    .subscribe(() => {
      this.isMenuCollapsed = true
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._document.removeEventListener('scroll', this.onContentScrolled);
  }

  onContentScrolled = (e: any) => {
    let scroll = window.pageYOffset;
    if (scroll > this.currentPosition) {
      if (scroll >= 50) {
        this.enableNavbarStyle = true;
      }
    } else {
      if (scroll <= 50) {
        this.enableNavbarStyle = false;
      }
    }
    this.currentPosition = scroll;
  }

}
