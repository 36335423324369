<div class="container-fluid background"></div>
<div class="container-fluid my-5">
  <div class="container">
    <div class="row">
      <div class="col">
        <h2 class="text-center catamaran-semiBold">Our Team</h2>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-sm-6" *ngFor="let member of group.members; index as idx">
        <div class="card h-100">
          <div class="card-body p-4">
            <div class="d-flex flex-column mb-3" style="gap: 16px">
              <!-- <img
                src="../../../assets/profile.jpg"
                [alt]="member.name"
                [id]="'image-' + idx"
                width="128px"
                height="128px"
                class="img-fluid rounded-circle"
              /> -->
              <h3 class="catamaran-semiBold card-title m-0">
                {{ member.name }}
              </h3>
              <div class="catamaran-regular" *ngFor="let point of member.points">
                {{ point }}
              </div>
            </div>
            <!-- <ul class="list-group list-group-flush">
              <li class="list-group-item border-0" >
                {{ point }}.
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->