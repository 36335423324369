import { Component } from '@angular/core';
import { groupInfo } from 'src/data';
@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.scss']
})
export class GroupInfoComponent {
  group = groupInfo
}
