<div class="container-fluid background"></div>
<div class="container-fluid">
	<div class="container my-5 ">
		<div class="row ">
			<div class="col">
				<div class="d-flex flex-column justify-content-center align-self-center">
					<h2 class="text-center catamaran-semiBold">
						Cars Catalogue
					</h2>
					<div class="row mt-5">
						<div class="col-12 col-sm-3 mb-4" *ngFor="let car of carsData; index as idx;">
							<div class="card rounded-4 border border-1 border-opacity-10 shadow-sm h-100">
								<div class="card-header border-0 rounded-4 bg-white py-3">
									<h3 class="catamaran-semiBold">{{ car.brand }} {{ car.name }}</h3>
									<span class="catamaran-regular text-sm text-muted">Price Range:</span>
									<h5 class="catamaran-semiBold">{{ car.priceRange }}</h5>

								</div>
								<div class="card-body">
									<div class="d-flex flex-column justify-content-center align-items-center h-100">
										<img [src]="car.thumbnail" class="img-fluid" width="100%" height="200px">
									</div>
								</div>
								<div class="card-footer border-0 bg-white rounded-4 py-3">
									<button type="button" class="btn btn-primary w-100 rounded-3"
										[routerLink]="car.name | lowercase">
										<div class="d-flex align-items-center">
											<span class="flex-grow-1">Details</span>
											<span class="fw-bold">&#8594;</span>
										</div>
									</button>
								</div>
							</div>
						</div>
						<!-- <div class="col-12 col-sm-3" *ngFor="let car of carsData; index as idx; odd as odd; even as even">
							<div class="card text-center h-100">
								<div class="card-body px-0">
									<div class="col-12 col-sm-6">
										<img [src]="car.thumbnail" class="mx-auto" style="height: 150px;">
									</div>
									<div class="card-footer border-0 bg-white">
										<h4>{{ car.brand }} {{ car.name}}</h4>
										<a [routerLink]="car.name | lowercase" class="stretched-link"></a>
									</div>
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>