<div class="container-fluid background"></div>
<div class="container-fluid" style="
  background: url('./../../../assets/bg12.jpg');
  background-position: center;
  background-size: 100%;
">
  <div class="container py-5">
    <div class="row">
      <div class="col">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <h2 class="catamaran-semiBold m-0 mb-2">Contact Us</h2>
          <h6 class="catamaran-regular">Have an Query? Feel Free to contact us</h6>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 col-sm-6">
        <div class="ratio ratio-21x9">
          <iframe [src]="mapLink" allowfullscreen="" frameBorder="0" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="d-flex flex-column mb-3">
          <span class="text-sm text-muted mb-1 catamaran-regular">Email</span>
          <a href="mailto:sidham.motors2323@gmail.com" target="_blank" class="nav-link">
            <h4 class="catamaran-semiBold">sidham.motors2323@gmail.com</h4>
          </a>
        </div>
        <div class="d-flex flex-column mb-3">
          <span class="text-sm text-muted mb-1 catamaran-regular">Phone</span>
          <a href="tel:+919872312323" target="_blank" class="nav-link">
            <h4 class="catamaran-semiBold">9872312323</h4>
          </a>
        </div>
        <div class="d-flex flex-column mb-3">
          <span class="text-sm text-muted mb-1 catamaran-regular">Address</span>
          <h4 class="catamaran-semiBold">E11-12 Industrial Area, Near ITI Chowk, Bathinda 151001</h4>
        </div>
        <!-- <form [formGroup]="queryForm" (ngSubmit)="onSubmit($event)">
          <div class="row">
            <div class="col-12 col-sm-6 mb-2">
              <label class="form-label" for="name">Name</label>
              <input type="text" class="form-control" formControlName="name">
            </div>
            <div class="col-12 col-sm-6 mb-2">
              <label class="form-label" for="phone">Phone</label>
              <input type="tel" class="form-control" formControlName="phone">
            </div>
            <div class="col-12 mb-2">
              <label class="form-label" for="email">Email</label>
              <input type="email" class="form-control" formControlName="email">
            </div>
            <div class="col-12 mb-2">
              <label class="form-label" for="query">Query</label>
              <textarea name="query" id="query" rows="4" formControlName="query" class="form-control"></textarea>
            </div>
            <div class="col-12">
              <button type="button" class="btn btn-dark mt-2">Submit</button>
            </div>
          </div>
        </form> -->
      </div>
    </div>
    <!-- <div class="row mt-5">
      <div class="col-12 col-sm-6">
        <div class="shadow card">
          <form (ngSubmit)="onSubmit($event);">
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="name">Name</label>
                <input type="text" class="form-control" formControlName="name">
              </div>
              <div class="mb-3">
                <label class="form-label" for="phone">Phone</label>
                <input type="tel" class="form-control" formControlName="phone">
              </div>
              <div class="mb-3">
                <label class="form-label" for="email">Email</label>
                <input type="email" class="form-control" formControlName="email">
              </div>
              <div class="mb-3">
                <label class="form-label" for="query">Query</label>
                <textarea name="query" id="query" rows="5" formControlName="query" class="form-control"></textarea>
              </div>

              <div class="alert alert-success" role="alert" *ngIf="formSubmitted">
                <h4 class="alert-heading">Form Submitted!</h4>
                <p>We have received your query. Will get back to you soon.</p>
              </div>
            </div>
            <div class="card-footer bg-white border-0">
              <div class="col px-0">
                <button type="submit" class="btn btn-lg btn-primary w-100" [disabled]="formSubmitted">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-12 col-sm-6 mt-3 mt-sm-0">
        <img src="../../../assets/contact.png" style="width: 100%; height: auto;">
      </div>
    </div> -->
  </div>
</div>