import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { carsPage, errorHandler } from 'src/data';

@Component({
  selector: 'app-car-details',
  templateUrl: './car-details.component.html',
  styleUrls: ['./car-details.component.scss']
})
export class CarDetailsComponent {

  errorHandler = errorHandler;

  private routeSub: Subscription;
  constructor(private route: ActivatedRoute) { }

  car: any = null;

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      // console.log(params) //log the entire params object
      // console.log(params['car']) //log the value of id
      this.car = carsPage.cars.find(e => e.name.toLocaleLowerCase() === params['car'])
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
