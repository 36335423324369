export function errorHandler(event: any) {
  event.target.src = './../../../assets/favicon.jpeg';
}

const homePage = {
  images: [
    '../../../assets/officeImages/2.webp',
    '../../../assets/officeImages/5.webp',
    '../../../assets/officeImages/16.webp',
    '../../../assets/officeImages/28.webp',
    '../../../assets/officeImages/24.webp',
  ],

  featuredCars: [
    {
      image: './../../../assets/cars/tiago/gallery/6.jpg',
      brand: 'Tata',
      name: 'Tiago',
      info: 'It is a hatchback that is designed for the urban commuter. The Tiago has a sleek design and comes equipped with a range of features, such as touchscreen infotainment, automatic climate control, and rear parking sensors, that make it a great value for money option in its segment.',
    },
    {
      image: './../../../assets/cars/tigor/gallery/3.jpg',
      brand: 'Tata',
      name: 'Tigor',
      info: 'The Tigor is a compact sedan that has a stylish design and comes loaded with features such as a touchscreen infotainment system, automatic climate control, and a rearview camera. It is a perfect blend of style and practicality, making it an ideal choice for those who want a car that stands out from the crowd.',
    },
    {
      image: './../../../assets/cars/nexon/gallery/5.jpg',
      brand: 'Tata',
      name: 'Nexon',
      info: 'The Nexon has a bold and muscular design and is equipped with a range of features such as a touchscreen infotainment system, automatic climate control, and a rearview camera. It is a great option for those who want a car that is both rugged and stylish.',
    },
    {
      image: './../../../assets/cars/harrier/gallery/3.png',
      brand: 'Tata',
      name: 'Harrier',
      info: "The Harrier is Tata's flagship SUV that is designed to take on the likes of the Jeep Compass and the Hyundai Creta. It has a bold and muscular design and comes loaded with features such as a panoramic sunroof, automatic climate control, and a touchscreen infotainment system. The Harrier is also equipped with advanced safety features such as hill hold control, hill descent control, and traction control, making it a great option for those who prioritize safety.",
    },
  ],
};

const galleryPage = {
  galleryList: [
    '../../../assets/gallery/1.jpg',
    '../../../assets/gallery/2.jpg',
    '../../../assets/gallery/3.jpg',
    '../../../assets/gallery/4.jpg',
    '../../../assets/gallery/5.jpg',
    '../../../assets/gallery/6.jpg',
    '../../../assets/gallery/7.jpg',
    '../../../assets/gallery/8.jpg',
    '../../../assets/gallery/9.jpg',
    '../../../assets/gallery/10.jpg',
    '../../../assets/gallery/11.jpg',
    '../../../assets/gallery/12.jpg',
    '../../../assets/gallery/13.jpg',
    '../../../assets/gallery/14.jpg',
    '../../../assets/gallery/15.jpg',
    '../../../assets/gallery/16.jpg',
    '../../../assets/gallery/17.jpg',
    '../../../assets/gallery/18.jpg',
    '../../../assets/gallery/19.jpg',
    '../../../assets/gallery/20.jpg',
    '../../../assets/gallery/21.jpg',
    '../../../assets/gallery/22.jpg',
    '../../../assets/gallery/23.jpg',
    '../../../assets/gallery/24.jpg',
    '../../../assets/gallery/25.jpg',
    '../../../assets/gallery/26.jpg',
    '../../../assets/gallery/27.jpg',
    '../../../assets/gallery/28.jpg',
    '../../../assets/gallery/29.jpg',
    '../../../assets/gallery/30.jpg',
    '../../../assets/gallery/31.png',
    '../../../assets/gallery/32.png',
    '../../../assets/gallery/33.png',
    '../../../assets/gallery/34.png',
    '../../../assets/gallery/35.png',
    '../../../assets/gallery/36.png',
    '../../../assets/gallery/37.png',
    '../../../assets/gallery/38.png',
    '../../../assets/gallery/39.png',
    '../../../assets/gallery/40.png',
    '../../../assets/gallery/41.png',
    '../../../assets/gallery/42.png',
  ],
};

const aboutUS = {
  name: 'About Sidham Motors',
  points: [
    'Incorporated in 2008-2009',
    'Authorised Suzuki Two wheeler dealer till 2021 through old dirctors',
    'Taken Over by Pranav Bansal & Krishan Bansal as Directors in September 2022 and all old directors resigned',
  ],
};

const outlets = {
  addresses: [
    {
      locationData:
        'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d881823.5820625599!2d75.90185300000002!3d30.304782!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391732934fb7f613%3A0x3c3311a752bad97e!2sTata%20Motors%20Cars%20Showroom%20-%20Sidham%20Motors%2C%20Bathinda!5e0!3m2!1sen!2sin!4v1679642034726!5m2!1sen!2sin',
      name: 'Sidham Motors',
      type: 'Showroom',
      address: 'Z8-01436, Nachhatar Nagar, Opposite IVY Hospital, Mansa Road',
      pincode: '151001',
      city: 'Bathinda',
      state: 'Punjab',
    },
    {
      locationData:
        'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3448.6164034250555!2d74.95752981511856!3d30.190949881829273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDExJzI3LjQiTiA3NMKwNTcnMzUuMCJF!5e0!3m2!1sen!2sin!4v1683973902034!5m2!1sen!2sin',
      name: 'Sidham Motors',
      type: 'Workshop',
      address: 'E11-12, Industrial Area, Near ITI Chowk',
      pincode: '151001',
      city: 'Bathinda',
      state: 'Punjab',
    },
    {
      locationData:
        'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d431.7630614014537!2d75.40146728934937!3d30.033859969788022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAyJzAyLjIiTiA3NcKwMjQnMDYuNiJF!5e0!3m2!1sen!2sin!4v1683971189458!5m2!1sen!2sin',
      name: 'Sidham Motors',
      type: 'Showroom',
      address: 'Kenchiya College road Mansa',
      pincode: '151505',
      city: 'Bathinda',
      state: 'Punjab',
    },
  ],
};

const groupInfo = {
  members: [
    {
      name: 'Pranav Bansal',
      points: [
        "He has been handling a 40-year-old family Pharma Consignee Distribution Business in Punjab for the past nine years. With an MBA in Marketing and Sales from Amity University, Noida, they have established a strong distribution and social network in Bathinda, owing to their family's native place. He has expanded the existing family business by adding various verticals and initiating a patient assistance program under MSD Pharmaceuticals that provides loans to patients for therapy, which was the first of its kind in India.",
        "He has an open-minded approach to new challenges, take a keen interest in day-to-day business operations, and keep a regular check on staff. Additionally, the writer is interested in entering into a business with low or safe credits and values the opinion of elders before making major decisions. They are also adept at handling human resources and are an admirer of the TATA brand.",
      ],
    },
    {
      name: 'Krishan K Bansal',
      points: [
        "He has been managing the oil consignee business for Bunge Oil Pvt. Ltd. and has previously worked as the director of BCL Industries in Bathinda, where they managed the oil solvent, rice plant, and oil refinery for 20 years. They are socially well connected to both government and private institutions, and currently handle an annual turnover of Rs. 100 crores. Additionally, the writer is known for their quick and strong decision-making skills."
      ],
    },
  ],
  images: [
    '../../../assets/officeImages/1.webp',
    '../../../assets/officeImages/2.webp',
    '../../../assets/officeImages/3.webp',
    '../../../assets/officeImages/4.webp',
    '../../../assets/officeImages/5.webp',
    '../../../assets/officeImages/6.webp',
    '../../../assets/officeImages/7.webp',
    '../../../assets/officeImages/8.webp',
    '../../../assets/officeImages/9.webp',
    '../../../assets/officeImages/10.webp',
    '../../../assets/officeImages/11.webp',
    '../../../assets/officeImages/12.webp',
    '../../../assets/officeImages/13.webp',
    '../../../assets/officeImages/14.webp',
    '../../../assets/officeImages/15.webp',
    '../../../assets/officeImages/16.webp',
    '../../../assets/officeImages/17.webp',
    '../../../assets/officeImages/19.webp',
    '../../../assets/officeImages/20.webp',
    '../../../assets/officeImages/21.webp',
    '../../../assets/officeImages/22.webp',
    '../../../assets/officeImages/23.webp',
    '../../../assets/officeImages/24.webp',
    '../../../assets/officeImages/25.webp',
    '../../../assets/officeImages/26.webp',
    '../../../assets/officeImages/27.webp',
    '../../../assets/officeImages/28.webp',
    '../../../assets/officeImages/29.webp',
    '../../../assets/officeImages/30.webp',
    '../../../assets/officeImages/31.webp',
    '../../../assets/officeImages/32.webp',
    '../../../assets/officeImages/33.webp',
    '../../../assets/officeImages/34.webp',
    '../../../assets/officeImages/35.webp',
    '../../../assets/officeImages/36.webp',
    '../../../assets/officeImages/37.webp',
    '../../../assets/officeImages/38.webp',
    '../../../assets/officeImages/39.webp',
    '../../../assets/officeImages/40.webp',
    '../../../assets/officeImages/41.webp',
    // '../../../assets/officeImages/42.webp',
    '../../../assets/officeImages/43.webp',
    '../../../assets/officeImages/44.webp',
    '../../../assets/officeImages/45.webp',
    '../../../assets/officeImages/46.webp',
    '../../../assets/officeImages/47.webp',
    '../../../assets/officeImages/48.webp',
    '../../../assets/officeImages/49.webp',
    // '../../../assets/officeImages/50.webp',
    '../../../assets/officeImages/51.webp',
    '../../../assets/officeImages/52.webp',
    '../../../assets/officeImages/53.webp',
    '../../../assets/officeImages/54.webp',
    '../../../assets/officeImages/55.webp',
    '../../../assets/officeImages/56.webp',
    '../../../assets/officeImages/57.webp',
    '../../../assets/officeImages/58.webp',
    '../../../assets/officeImages/59.webp',
    '../../../assets/officeImages/60.webp',
    '../../../assets/officeImages/61.webp',
    '../../../assets/officeImages/62.webp',
    '../../../assets/officeImages/63.webp',
    '../../../assets/officeImages/64.webp',
  ]
};

const carsPage = {
  cars: [
    {
      thumbnail: '../../../assets/cars/tiago/thumbnail.png',
      bannerImages: [
        '../../../assets/cars/tiago/banner1.png',
        '../../../assets/cars/tiago/banner2.jpg',
        '../../../assets/cars/tiago/banner3.jpg',
      ],
      brand: 'Tata',
      name: 'Tiago',
      priceRange: '₹5.54 - 8.00 Lakh',
      images: [
        '../../../assets/cars/tiago/gallery/2.jpg',
        '../../../assets/cars/tiago/gallery/3.jpg',
        '../../../assets/cars/tiago/gallery/4.jpg',
        '../../../assets/cars/tiago/gallery/5.jpg',
        '../../../assets/cars/tiago/gallery/6.jpg',
        '../../../assets/cars/tiago/gallery/7.jpg',
        '../../../assets/cars/tiago/gallery/8.jpg',
        '../../../assets/cars/tiago/gallery/9.jpg',
        '../../../assets/cars/tiago/gallery/10.jpg',
        '../../../assets/cars/tiago/gallery/11.jpg',
        '../../../assets/cars/tiago/gallery/12.jpg',
      ],
      features: [
        {
          image: '../../../assets/cars/tiago/feature1.jpg',
          title: 'Energy Absorbing Body Structure',
          tagLine:
            'Strong body structure that can absorb impact energy, offering superior safety with 4 star GNCAP rating',
        },
        {
          image: '../../../assets/cars/tiago/feature2.jpg',
          title: 'Dual Airbags',
          tagLine:
            'Prevents the occupants from striking hard surfaces like dashboard during an impact, thereby,  minimizing injuries',
        },
        {
          image: '../../../assets/cars/tiago/feature3.jpg',
          title: 'Rear Parking Camera With Dynamic Guidelines',
          tagLine:
            'A Easy parking in tighter spaces while reversing. Guidelines for added safety',
        },
        {
          image: '../../../assets/cars/tiago/feature4.jpg',
          title: 'Tyre Pressure Monitoring System',
          tagLine:
            'TPMS enables you to maintain and check the right tyre pressure resulting in improved vehicle handling and fuel efficiency.',
        },
        {
          image: '../../../assets/cars/tiago/feature5.jpg',
          title: 'ABS With EBD And Corner Stability Control',
          tagLine:
            'Enhances safety during panic braking and provides directional control',
        },
        {
          image: '../../../assets/cars/tiago/feature6.jpg',
          title: 'Follow me home Lamps',
          tagLine:
            'Allows you to find your way through the darkness when you are leaving the car or heading towards the car',
        },
        {
          image: '../../../assets/cars/tiago/feature7.jpg',
          title: 'Rear Wash Wiper with Defogger',
          tagLine: 'For clear rear visibility during rains or in winter',
        },
        {
          image: '../../../assets/cars/tiago/feature8.jpg',
          title: 'Speed Sensitive Auto Door Locking',
          tagLine:
            'Ensures that doors don’t open by accident when the car is in motion. Added safety',
        },
        {
          image: '../../../assets/cars/tiago/feature9.jpg',
          title: 'Puncture Repair Kit',
          tagLine:
            'For added Safety and Convenience - Easy to use repair kit for sealing and reinflating a punctured tire',
        },
      ],
    },
    {
      thumbnail: '../../../assets/cars/altroz/thumbnail.png',
      bannerImages: [
        '../../../assets/cars/altroz/banner1.png',
        '../../../assets/cars/altroz/banner2.jpg',
        '../../../assets/cars/altroz/banner3.jpg',
      ],
      brand: 'Tata',
      name: 'Altroz',
      priceRange: '₹6.45 - 10.4 Lakh',
      images: [
        '../../../assets/cars/altroz/gallery/1.jpg',
        '../../../assets/cars/altroz/gallery/2.jpg',
        '../../../assets/cars/altroz/gallery/3.jpg',
        '../../../assets/cars/altroz/gallery/4.jpg',
        '../../../assets/cars/altroz/gallery/5.jpg',
        '../../../assets/cars/altroz/gallery/6.jpg',
        '../../../assets/cars/altroz/gallery/7.jpg',
        '../../../assets/cars/altroz/gallery/8.jpg',
        '../../../assets/cars/altroz/gallery/9.jpg',
        '../../../assets/cars/altroz/gallery/10.jpg',
        '../../../assets/cars/altroz/gallery/11.jpg',
        '../../../assets/cars/altroz/gallery/12.jpg',
        '../../../assets/cars/altroz/gallery/13.jpg',
        '../../../assets/cars/altroz/gallery/14.jpg',
        '../../../assets/cars/altroz/gallery/15.jpg',
        '../../../assets/cars/altroz/gallery/16.jpg',
        '../../../assets/cars/altroz/gallery/17.jpg',
        '../../../assets/cars/altroz/gallery/18.jpg',
        '../../../assets/cars/altroz/gallery/19.jpg',
        '../../../assets/cars/altroz/gallery/20.jpg',
        '../../../assets/cars/altroz/gallery/21.jpg',
        '../../../assets/cars/altroz/gallery/22.jpg',
        '../../../assets/cars/altroz/gallery/23.jpg',
        '../../../assets/cars/altroz/gallery/24.jpg',
        '../../../assets/cars/altroz/gallery/25.jpg',
        '../../../assets/cars/altroz/gallery/26.jpg',
        '../../../assets/cars/altroz/gallery/27.jpg',
        '../../../assets/cars/altroz/gallery/28.jpg',
        '../../../assets/cars/altroz/gallery/29.jpg',
        '../../../assets/cars/altroz/gallery/30.jpg',
      ],
      features: [
        {
          image: '../../../assets/cars/altroz/feature1.jpg',
          title: 'GOLD STANDARD OF SAFETY',
          tagLine:
            'ALTROZ has been designed to offer world-class protection to its occupants. Equipped with ALFA Architecture and a comprehensive safety system ALTROZ has achieved 5-star Global NCAP rating.',
        },
        {
          image: '../../../assets/cars/altroz/feature2.jpg',
          title: 'Agile Light Flexible Advanced Architecture (ALFA Arc)',
          tagLine:
            'ALFA Arc along with energy-absorbing body structure acccounts for the absolute safety of the passengers.',
        },
        {
          image: '../../../assets/cars/altroz/feature3.jpg',
          title: 'Anti-lock Braking System (ABS) with Brake Sway Control (BSC)',
          tagLine:
            'ABS 9.3 version prevents wheel lock-up during panic braking, thereby maintaining directional stability.',
        },
        {
          image: '../../../assets/cars/altroz/feature4.jpg',
          title: 'Electronic Brake-force Distribution (EBD)',
          tagLine:
            'EBD system distributes the brake-force between front and rear axle to maintain efficient on-road control.',
        },
        {
          image: '../../../assets/cars/altroz/feature5.jpg',
          title: 'Corner Stability Control (CSC)',
          tagLine:
            'Stay in absolute control, especially while undertaking sharp or speedy turns.',
        },
        {
          image: '../../../assets/cars/altroz/feature6.jpg',
          title: 'Dual Front Airbags',
          tagLine:
            'Dual front airbags help ensure complete and thorough protection for front passengers in case of a collision.',
        },
        {
          image: '../../../assets/cars/altroz/feature7.jpg',
          title: 'Reverse Parking camera',
          tagLine:
            'Increased visibility behind the wheel assist in easy parking in tighter spaces with the help of guidelines.',
        },
        {
          image: '../../../assets/cars/altroz/feature8.jpg',
          title: 'ISOFIX Anchorage for child seats',
          tagLine:
            'Keep your little ones safe with an easy installation system for child seats to make every drive blissful and relaxed.',
        },
        {
          image: '../../../assets/cars/altroz/feature9.jpg',
          title: 'Voice Alerts',
          tagLine:
            'Timely voice alerts and warning prevents you from unwanted mishaps and all alerts',
        },
        {
          image: '../../../assets/cars/altroz/feature10.jpg',
          title: 'iTPMS',
          tagLine:
            'The iTPMS alerts you when your car is on low tire pressure. This enables you to maintain the right tire pressure resulting in improved vehicle handling and fuel efficiency.',
        },
        {
          image: '../../../assets/cars/altroz/feature11.jpg',
          title: 'Parking Assistance',
          tagLine:
            'Increased visibility behind the wheel assist in easy parking in tighter spaces with the help of guidelines.',
        },
        {
          image: '../../../assets/cars/altroz/feature12.jpg',
          title: 'Height Adjustable Seatbelt',
          tagLine:
            'Adjustable seatbelts as per individual requirements to provide comprehensive safety.',
        },
        {
          image: '../../../assets/cars/altroz/feature13.jpg',
          title: 'Auto Park lock',
          tagLine:
            'Exclusive to the ALTROZ DCA, we have offered the first in class smart safety feature – the Auto Park Lock, which will automatically engage preventing the vehicle from unwanted movement when car the is not in Park mode',
        },
        {
          image: '../../../assets/cars/altroz/feature14.jpg',
          title: 'Fog Lamps',
          tagLine:
            'Front fog lamps seamlessly integrated with the bumper help provide improved visibility even in poor weather conditions.',
        },
      ],
    },
    {
      thumbnail: '../../../assets/cars/tigor/thumbnail.png',
      bannerImages: [
        '../../../assets/cars/tigor/banner1.png',
        '../../../assets/cars/tigor/banner2.png',
      ],
      brand: 'Tata',
      name: 'Tigor',
      priceRange: '₹6.19 - 8.20 Lakh',
      images: [
        '../../../assets/cars/tigor/gallery/1.jpg',
        '../../../assets/cars/tigor/gallery/2.jpg',
        '../../../assets/cars/tigor/gallery/3.jpg',
        '../../../assets/cars/tigor/gallery/4.jpg',
        '../../../assets/cars/tigor/gallery/5.jpg',
        '../../../assets/cars/tigor/gallery/6.jpg',
        '../../../assets/cars/tigor/gallery/7.jpg',
        '../../../assets/cars/tigor/gallery/8.jpg',
        '../../../assets/cars/tigor/gallery/9.jpg',
        '../../../assets/cars/tigor/gallery/10.jpg',
      ],
      features: [
        {
          image: '../../../assets/cars/tigor/feature1.jpg',
          title: 'DUAL FRONT AIRBAGS',
          tagLine:
            'Prevents the occupants from striking hard surfaces like dashboard during an impact, thereby, minimizing injuries.',
        },
        {
          image: '../../../assets/cars/tigor/feature2.jpg',
          title: 'ABS WITH EBD AND CORNER STABILITY CONTROL',
          tagLine:
            'Enhanced safety during panic braking and provides directional control.',
        },
        {
          image: '../../../assets/cars/tigor/feature3.jpg',
          title: 'REAR CAMERA WITH DYNAMIC GUIDELINES',
          tagLine:
            'Alerts the driver in case of any obstacles while reversing. Easy parking in tighter spaces with the help of guidelines.',
        },
        {
          image: '../../../assets/cars/tigor/feature4.jpg',
          title: 'FRONT FOGLAMP WITH CHROME RING',
          tagLine:
            'Cruise through dense fog with improved clarity in low visibility conditions.',
        },
        {
          image: '../../../assets/cars/tigor/feature5.jpg',
          title: 'REAR DEFOGGER',
          tagLine: 'For clear rear visibility at during rains or in winter.',
        },
        {
          image: '../../../assets/cars/tigor/feature6.jpg',
          title: 'SPEED SENSITIVE AUTO DOOR LOCKING',
          tagLine:
            'Ensures that doors don’t open by accident when the car is in motion. Added safety.',
        },
        {
          image: '../../../assets/cars/tigor/feature7.jpg',
          title: 'PUNCTURE REPAIR KIT',
          tagLine:
            'For added Safety and Convenience - Easy to use repair kit for sealing and reinflating a punctured tire.',
        },
      ],
    },
    {
      thumbnail: '../../../assets/cars/punch/thumbnail.png',
      bannerImages: [
        '../../../assets/cars/punch/banner1.jpg',
        '../../../assets/cars/punch/banner2.png',
      ],
      brand: 'Tata',
      name: 'Punch',
      priceRange: '₹5.99 - 9.47 Lakh',
      images: [
        '../../../assets/cars/punch/gallery/1.jpg',
        '../../../assets/cars/punch/gallery/2.jpg',
        '../../../assets/cars/punch/gallery/3.jpg',
        '../../../assets/cars/punch/gallery/4.jpg',
        '../../../assets/cars/punch/gallery/5.jpg',
        '../../../assets/cars/punch/gallery/6.jpg',
        '../../../assets/cars/punch/gallery/7.jpg',
        '../../../assets/cars/punch/gallery/8.jpg',
        '../../../assets/cars/punch/gallery/9.jpg',
        '../../../assets/cars/punch/gallery/10.jpg',
        '../../../assets/cars/punch/gallery/11.jpg',
        '../../../assets/cars/punch/gallery/12.jpg',
      ],
      features: [
        {
          image: '../../../assets/cars/punch/feature1.jpg',
          title: 'PROJECTOR HEADLAMPS & LED DRLS',
          tagLine: 'That shine as bright as you.',
        },
        {
          image: '../../../assets/cars/punch/feature2.jpg',
          title: 'BOLD LED TAIL LAMPS',
          tagLine: 'That grab as much attention as you.',
        },
        {
          image: '../../../assets/cars/punch/feature3.jpg',
          title: 'R16 DIAMOND CUT ALLOY WHEELS',
          tagLine: "That adds to the Punch's aura.",
        },
        {
          image: '../../../assets/cars/punch/feature4.jpg',
          title: 'STYLISH ROOF RAILS',
          tagLine: 'Gives it a premium look.',
        },
        {
          image: '../../../assets/cars/punch/feature5.jpg',
          title: '90 DEGREE OPENING DOORS',
          tagLine: 'For a hassle free entry and exit.',
        },
        {
          image: '../../../assets/cars/punch/feature6.jpg',
          title: 'SIGNATURE HUMANITY LINE',
          tagLine: 'Brings out the design philosophy with style.',
        },
        {
          image: '../../../assets/cars/punch/feature7.jpg',
          title: 'CLADDING ON DOORS, WHEEL ARCHES AND SILL CLADDING',
          tagLine: 'That makes the PUNCH look even more bold and masculine.',
        },
        {
          image: '../../../assets/cars/punch/feature8.jpg',
          title: 'EYE CATCHING DASHBOARD',
          tagLine: 'That looks premium and feels stylish.',
        },
        {
          image: '../../../assets/cars/punch/feature9.jpg',
          title: 'PREMIUM UPHOLSTERY WITH TRI-ARROW DESIGN.',
          tagLine: 'That makes you feel like royalty.',
        },
        {
          image: '../../../assets/cars/punch/feature10.jpg',
          title: 'SPACIOUS INTERIORS & REAR FLAT FLOORS',
          tagLine: 'Because nobody likes to feel cramped up.',
        },
        {
          image: '../../../assets/cars/punch/feature11.jpg',
          title: 'BODY COLORED AIRVENTS',
          tagLine: 'That simply add some style to the dashboard.',
        },
        {
          image: '../../../assets/cars/punch/feature1.jpg',
          title: 'D CUT STEERING WHEEL',
          tagLine: 'That looks stylish and also handles better.',
        },
      ],
    },
    {
      thumbnail: '../../../assets/cars/safari/thumbnail.png',
      bannerImages: [
        '../../../assets/cars/safari/banner1.png',
        '../../../assets/cars/safari/banner2.png',
      ],
      brand: 'Tata',
      name: 'Safari',
      priceRange: '₹15.65 - 25.00 Lakh',
      images: [
        '../../../assets/cars/safari/gallery/1.jpg',
        '../../../assets/cars/safari/gallery/2.jpg',
        '../../../assets/cars/safari/gallery/3.jpg',
        '../../../assets/cars/safari/gallery/4.jpg',
        '../../../assets/cars/safari/gallery/5.jpg',
        '../../../assets/cars/safari/gallery/6.jpg',
        '../../../assets/cars/safari/gallery/7.jpg',
        '../../../assets/cars/safari/gallery/8.jpg',
        '../../../assets/cars/safari/gallery/9.jpg',
        '../../../assets/cars/safari/gallery/10.jpg',
        '../../../assets/cars/safari/gallery/11.jpg',
      ],
      features: [
        {
          image: '../../../assets/cars/safari/feature1.jpg',
          title: 'ADAS',
          tagLine:
            'Advanced Driver Assistance Systems (ADAS) are Advanced technology Solutions intended to enhance the road safety by preventing or reducing the impact of potential accidents. ADAS systems activate safety measures by alerting the driver and initiating possible safeguard measures in the vehicle. ADAS by Tata Motors is an intuitive driving technology which is built to take on any surprises while driving, so that you can experience a safe journey with your loved ones.',
        },
        {
          image: '../../../assets/cars/safari/feature2.jpg',
          title: 'MAJESTIC DESIGN',
          tagLine:
            'The Safari’s unique and domineering stance coupled with imposing proportions, lend it a tremendous road presence powered with a graceful agility and an all-purpose nature. The smart and expressive surface treatment brings in a surreal dynamism. The interiors of the new Safari too have been crafted with exquisite deft to convey a classy, comfortable feel and to ensure that you can soak themselves in luxury during your journeys with the loved ones.',
        },
        {
          image: '../../../assets/cars/safari/feature3.jpg',
          title: 'GRAND LIFESTYLE',
          tagLine:
            'The New Safari is crafted with some of the finest workmanship on display, enriched with indulgent & luxurious interiors, made from high-quality materials, making it a truly unforgettable experience every time you take the wheel. Immaculate attention has been given to the finest of details in the new Safari. It comes loaded with a host of other features to complement your indulgent and active lifestyle.',
        },
        {
          image: '../../../assets/cars/safari/feature4.jpg',
          title: 'ELEVATED PERFORMANCE',
          tagLine:
            'With the New Safari, no matter where it’s taken, the refined ride & handling and a thrilling drive powers the journey. With the 170 PS Kryotec Engine, 6 Speed AT, ESP Terrain response modes and Multi Drive Modes, Safari delivers a new benchmark of drivability, and driving pleasure.',
        },
        {
          image: '../../../assets/cars/safari/feature5.jpg',
          title: 'PEDIGREE OF OMEGARC',
          tagLine:
            'The new Safari further builds on the proven capability of OMEGARC, an architecture derived from the renowned D8 platform from Land Rover, which in itself is the gold standard of SUVs worldwide. Being the 2nd vehicle on OMEGARC, it carries forward this legendary pedigree.',
        },
        {
          image: '../../../assets/cars/safari/feature6.jpg',
          title: 'IN-VOGUE INFOTAINMENT & CONNECTIVITY',
          tagLine:
            'The New Safari has evolved to be in sync with the technologically savvy SUV customer of today. The Floating Island Infotainment system is designed not just to offer the premium audio experience that customers expect, it also comes loaded with iRA, our connected car technology to take the connectivity to the next level.',
        },
        {
          image: '../../../assets/cars/safari/feature7.jpg',
          title: 'COCOONED SAFETY',
          tagLine:
            'Nothing is more important than safety, and the new Safari ensures that all its occupants are cocooned in an impenetrable bubble of safety once inside the vehicle. The new Safari not only possesses the inherent strengths of OMEGARC, it also comes loaded with top-of-the-line safety features to ensure active and passive safety.',
        },
      ],
    },
    {
      thumbnail: '../../../assets/cars/harrier/thumbnail.png',
      bannerImages: [
        '../../../assets/cars/harrier/banner1.png',
        '../../../assets/cars/harrier/banner2.jpg',
      ],
      brand: 'Tata',
      name: 'Harrier',
      priceRange: '₹15.00 - 24.00 Lakh',
      images: [
        '../../../assets/cars/harrier/gallery/1.png',
        '../../../assets/cars/harrier/gallery/2.png',
        '../../../assets/cars/harrier/gallery/3.png',
        '../../../assets/cars/harrier/gallery/4.png',
        '../../../assets/cars/harrier/gallery/5.png',
        '../../../assets/cars/harrier/gallery/6.png',
        '../../../assets/cars/harrier/gallery/7.png',
      ],
      features: [
        {
          image: '../../../assets/cars/harrier/feature1.jpg',
          title: 'ADAS',
          tagLine:
            'Advanced Driver Assistance System (ADAS) are Advanced Technology Solutions intended to enhance the road safety by preventing or reducing the impact of potential accidents. ADAS systems activate safety measures by alerting the driver and initiating possible safeguard measures in the vehicle. ADAS by Tata Motors is an intuitive driving technology which is built to take on any surprises while driving, so that you can experience a safe journey with your loved ones*.',
        },
        {
          image: '../../../assets/cars/harrier/feature2.png',
          title: 'STUNNING IMPACT DESIGN 2.0',
          tagLine:
            'Based on the Impact Design 2.0 philosophy, with strong proportions and expressive surfaces providing an incredible stance, powerful road presence and a sense of dynamism, Harrier is truly Above All.',
        },
        {
          image: '../../../assets/cars/harrier/feature3.png',
          title: 'EXHILARATING PERFORMANCE',
          tagLine:
            'With 6 speed Automatic/6 speed Manual Transmission mated to the next generation Kryotec 170 Turbocharged BS6 Phase 2 Diesel engine makes for an effortless driving experience.',
        },
        {
          image: '../../../assets/cars/harrier/feature4.png',
          title: 'PEDIGREE OF OMEGARC',
          tagLine:
            'Based on the OMEGA Architecture, with the rich pedigree of Land Rover’s legendary D8 platform running through its veins, the Harrier is a cut Above All.',
        },
        {
          image: '../../../assets/cars/harrier/feature5.jpg',
          title: 'ABSOLUTE CONVENIENCE',
          tagLine:
            'Best in class luxurious yet practical interiors and future ready infotainment ensures serene in- cabin experience with a blend of design flourish.',
        },
        {
          image: '../../../assets/cars/harrier/feature6.png',
          title: 'CLASS LEADING INFOTAINMENT',
          tagLine:
            'Next-gen infotainment with Wi-Fi based smartphone connectivity through Android Auto™ & Apple Car Play™ along with best in class voice recognition technology to access navigation, phone calls, music with a press of a button.',
        },
        {
          image: '../../../assets/cars/harrier/feature7.png',
          title: 'TOP-OF-THE-LINE SAFETY',
          tagLine:
            'Instilling a greater sense of confidence every time you take the wheel, the Harrier comes equipped with ESP & ABS as a standard feature ensuring safety in difficult driving conditions.',
        },
      ],
    },
    {
      thumbnail: '../../../assets/cars/nexon/thumbnail.png',
      bannerImages: [
        '../../../assets/cars/nexon/banner1.png',
        '../../../assets/cars/nexon/banner2.jpg',
        '../../../assets/cars/nexon/banner3.png',
        '../../../assets/cars/nexon/banner4.jpg',
      ],
      brand: 'Tata',
      name: 'Nexon',
      priceRange: '₹7.80 - 14.30 Lakh',
      images: [
        '../../../assets/cars/nexon/gallery/1.jpg',
        '../../../assets/cars/nexon/gallery/2.jpg',
        '../../../assets/cars/nexon/gallery/3.jpg',
        '../../../assets/cars/nexon/gallery/4.jpg',
        '../../../assets/cars/nexon/gallery/5.jpg',
        '../../../assets/cars/nexon/gallery/6.jpg',
        '../../../assets/cars/nexon/gallery/7.jpg',
        '../../../assets/cars/nexon/gallery/8.jpg',
        '../../../assets/cars/nexon/gallery/9.jpg',
        '../../../assets/cars/nexon/gallery/10.jpg',
      ],
      features: [
        {
          image: '../../../assets/cars/nexon/feature1.jpg',
          title: 'NEX LEVEL SAFETY',
          tagLine:
            'Nexon’s high-strength steel structure absorbs impact energy and protects the passenger during an unfortunate collision.',
        },
        {
          image: '../../../assets/cars/nexon/feature2.jpg',
          title: 'NEX LEVEL DESIGN',
          tagLine:
            'A box breaking SUV design that combines the practicality of an SUV with the style of a sports coupe.',
        },
        {
          image: '../../../assets/cars/nexon/feature3.jpg',
          title: 'NEX LEVEL TECHNOLOGY',
          tagLine:
            'Presenting iRA – a cool new connected tech platform that is in sync with India. It offers a range of innovative features that are especially developed keeping in mind the needs of the India car buyer',
        },
        {
          image: '../../../assets/cars/nexon/feature4.jpg',
          title: 'NEX LEVEL PERFORMANCE',
          tagLine:
            'Powered by 120PS Turbocharged engines, 6 speed transmission and three drive modes – ECO, CITY & SPORT, the NEXON provides a drive experience like no other.',
        },
        {
          image: '../../../assets/cars/nexon/feature5.jpg',
          title: 'NEX LEVEL COMFORT',
          tagLine:
            'The Nexon is geared with ventilated Leatherette seats in the front to keep you at a comfortable temperature no matter what the weather is outside.',
        },
      ],
    },
  ],
};

export { homePage, galleryPage, aboutUS, outlets, carsPage, groupInfo };
