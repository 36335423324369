import { Component } from '@angular/core';
import { homePage, galleryPage, errorHandler } from 'src/data';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  images = homePage.images
  galleryList = galleryPage.galleryList.slice(0, 6);
  featuredCars = homePage.featuredCars;

  selectedFeaturedCar = homePage.featuredCars[0];

  errorHandler = errorHandler;
}
