<div class="container-fluid background"></div>
<div class="container-fluid my-5">
	<div class="container">
		<div class="row">
			<div class="col">
				<h2 class="text-center catamaran-semiBold">Outlets</h2>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col mb-3" *ngFor="let address of outlets.addresses; index as idx;">
				<div class="card rounded-4" style="height: 100%;">
					<div class="ratio ratio-4x3 mb-2 ">
						<iframe [src]="getSafeURL(address.locationData)" class="rounded-4" allowfullscreen="" frameBorder="0" loading="eager"
								referrerpolicy="no-referrer-when-downgrade"></iframe>
					</div>
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center mb-2">
							<h3 class="catamaran-semiBold m-0">{{ address.name }}</h3>
							<h6 class="m-0"><span class="badge text-bg-dark rounded-pill">{{ address.type }}</span></h6>
						</div>
						<span class="catamaran-regular">{{ address.address }}, </span>
						<span>{{ address.city }}, {{ address.state }}, {{ address.pincode }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>