import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { galleryPage, groupInfo } from 'src/data';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  pageTitle: "Gallery" | "Office" = "Gallery";
  galleryList: string[] = [];

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
    if(this.router.url.replace("/", "").split("/")[0] === "gallery") {
      this.pageTitle = 'Gallery';
      this.galleryList = [ ...galleryPage.galleryList]
    } else {
      this.pageTitle = 'Office';
      this.galleryList = [...groupInfo.images]
    }
  }
}
