<div class="container-fluid">
  <div class="row">
    <div class="col px-0">
      <ngb-carousel>
        <ng-template ngbSlide *ngFor="let image of images; index as i">
          <div class="d-none d-lg-block" style="
              height: 600px;
              width: 100%;
            " [ngStyle]="{
              'background': 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 20%, transparent), url('+ images[i] +') no-repeat',
              'background-size': '100% 100%'
            }">

          </div>
          <div class="d-block d-lg-none" style="
              height: 300px;
              width: 100%;
            " [ngStyle]="{
              'background': 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 20%, transparent), url('+ images[i] +') no-repeat',
              'background-size': '100% 100%'
            }">

          </div>
          <!-- <div class="carousel-caption">
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div> -->
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>

<!-- Brand Story Section Start -->
<div class="container-fluid my-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="catamaran-semiBold text-center m-0 mb-1">Brand Story</h2>
        <div class="text-center text-muted catamaran-regular w-100">Drive NEW FOREVER. A Whole new range of cars from
          Tata Motors.</div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-sm-6 mb-3">
        <div class="card rounded-4 h-100 border-opacity-10">
          <div class="card-body">
            <div class="row" style="height: 100%;">
              <div class="col-12 col-lg-7 order-1 order-lg-0">
                <h4 class="catamaran-semiBold">Performance</h4>
                <span class="catamaran-regular">
                  We understand that performance is key when it comes to driving. That's why we offer a wide range of
                  cars that deliver exceptional power, speed, and handling. From sporty coupes to powerful SUVs, our
                  cars are designed to provide you with an exhilarating driving experience. With advanced engines,
                  responsive transmissions, and innovative technology, our cars offer unbeatable performance on the
                  road.
                </span>
              </div>
              <div class="col-12 col-lg-5 order-0 order-lg-1 mb-3 mb-lg-0">
                <img src="./../../../assets/cars/safari/feature5.jpg" alt="" class="img-fluid rounded-4 h-100 d-none d-lg-block shadow-sm"
                  style="object-fit: cover;">
                <img src="./../../../assets/cars/safari/feature5.jpg" alt="" class="img-fluid rounded-4 d-block d-lg-none shadow-sm"
                  style="object-fit: cover;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 mb-3">
        <div class="card rounded-4 h-100 border-opacity-10">
          <div class="card-body">
            <div class="row" style="height: 100%;">
              <div class="col-12 col-lg-7 order-1 order-lg-0">
                <h4 class="catamaran-semiBold">Design</h4>
                <span class="catamaran-regular">
                  We believe that design is just as important as performance and features. That's why our cars are
                  designed to look as good as they drive. With sleek lines, bold colors, and elegant styling, our cars
                  are built to turn heads and make a statement on the road. From classic designs to modern looks, we
                  have a wide range of cars that are sure to fit your style.
                </span>
              </div>
              <div class="col-12 col-lg-5 order-0 order-lg-1 mb-3 mb-lg-0">
                <img src="./../../../assets/cars/tiago/feature1.jpg" alt="" class="img-fluid rounded-4 h-100 d-none d-lg-block shadow-sm"
                  style="object-fit: cover;">
                <img src="./../../../assets/cars/tiago/feature1.jpg" alt="" class="img-fluid rounded-4 d-block d-lg-none shadow-sm"
                  style="object-fit: cover;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 mb-3">
        <div class="card rounded-4 h-100 border-opacity-10">
          <div class="card-body">
            <div class="row" style="height: 100%;">
              <div class="col-12 col-lg-7 order-1 order-lg-0">
                <h4 class="catamaran-semiBold">Safety</h4>
                <span class="catamaran-regular">
                  At Sidham Motors, safety is our top priority. That's why all of our cars come with advanced
                  safety features that provide you with peace of mind on the road. From airbags and anti-lock brakes to
                  advanced driver-assist systems, our cars are built to protect you and your passengers in the event of
                  an accident. We also offer a range of safety technologies that help you stay alert and aware on the
                  road, like lane departure warning and automatic high beams.
                </span>
              </div>
              <div class="col-12 col-lg-5 order-0 order-lg-1 mb-3 mb-lg-0">
                <img src="./../../../assets/cars/safari/feature7.jpg" alt="" class="img-fluid rounded-4 h-100 d-none d-lg-block shadow-sm"
                  style="object-fit: cover;">
                <img src="./../../../assets/cars/safari/feature7.jpg" alt="" class="img-fluid rounded-4 d-block d-lg-none shadow-sm"
                  style="object-fit: cover;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 mb-3">
        <div class="card rounded-4 h-100 border-opacity-10">
          <div class="card-body">
            <div class="row" style="height: 100%;">
              <div class="col-12 col-lg-7 order-1 order-lg-0">
                <h4 class="catamaran-semiBold">Features</h4>
                <span class="catamaran-regular">
                  Our cars are equipped with advanced features that enhance your driving experience and provide you with
                  the latest technology. From touch-screen displays to advanced audio systems, our cars are built to
                  keep you connected and entertained on the road. Our cars also come with advanced safety features like
                  blind-spot monitoring, adaptive cruise control, and automatic emergency braking to help you stay safe
                  on the road.
                </span>
              </div>
              <div class="col-12 col-lg-5 order-0 order-lg-1 mb-3 mb-lg-0">
                <img src="./../../../assets/cars/tigor/feature3.jpg" alt="" class="img-fluid rounded-4 h-100 d-none d-lg-block shadow-sm"
                  style="object-fit: cover;">
                <img src="./../../../assets/cars/tigor/feature3.jpg" alt="" class="img-fluid rounded-4 d-block d-lg-none shadow-sm"
                  style="object-fit: cover;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Brand Story Section End -->

<!-- Featured Car Section Start -->
<div class="container-fluid my-5 bg-light py-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="catamaran-semiBold text-center m-0 mb-1">Featured Cars</h2>
      </div>
    </div>
    <div class="row mt-4" style="height: 100%;">
      <div class="col-12 col-sm-6 order-1 order-sm-0">
        <div class="d-flex flex-column justify-content-center h-100 align-items-start">
          <h5 class="catamaran-regular m-0">TATA</h5>
          <h3 class="catamaran-semiBold display-4">{{ selectedFeaturedCar.name | uppercase }}</h3>

          <div class="catamaran-regular">
            {{ selectedFeaturedCar.info }}
          </div>

          <button type="button" class="btn btn-primary catamaran-semiBold mt-3" [routerLink]="['../cars', selectedFeaturedCar.name.toLowerCase()]">Know More</button>
        </div>
      </div>
      <div class="col-12 col-sm-6 order-0 order-sm-1 mb-3 mb-sm-0">
        <img [src]="selectedFeaturedCar.image" [alt]="'image-'+selectedFeaturedCar.name.toLowerCase()"
          class="img-fluid rounded-4 w-100 h-100 shadow-sm">
      </div>
    </div>
    <div class="row mt-4 d-none d-lg-block">
      <div class="col-12">
        <div class="d-flex flex-wrap justify-content-center align-items-center gap-1 gap-sm-3">
          <div class="card border-0 mb-3 mb-lg-0" *ngFor="let fCar of featuredCars; index as idx" style="cursor: pointer;"
            (click)="selectedFeaturedCar = fCar">
            <img [src]="fCar.image" alt="" class="card-img-top rounded-4 shadow-sm" width="128px" height="128px" style="object-fit: cover;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Featured Car Section End -->

<!-- Gallery Section Start -->
<div class="container-fluid my-5">
  <div class="container">
    <div class="row pb-2">
      <div class="col">
        <h2 class="catamaran-semiBold text-center">Gallery</h2>
      </div>
    </div>
    <div class="row py-2">
      <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let gallery of galleryList; index as idx">
        <a [href]="gallery" target="_blank">
          <img loading="eager" [src]="gallery" [alt]="idx" (error)="errorHandler($event)" class="img-fluid rounded-4">
        </a>
      </div>
    </div>
    <div class="row pb-4 ">
      <div class="col d-flex justify-content-center">
        <button type="button" class="btn btn-lg btn-outline-primary" [routerLink]="['/gallery']">
          Explore More
          <span class="bi bi-chevron-double-right"></span>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Gallery Section End -->

<!-- Director Message Start -->
<div class="container-fluid mt-5 py-5" style="
  background: url('./../../../assets/bg12.jpg');
  background-position: 100%;
  background-size: cover;
  backdrop-filter: invert(100%);
">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h4 class="catamaran-semiBold text-center">Director's Message</h4>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-sm-5">
        <div class="d-flex flex-column justify-content-center align-items-center h-100">
          <img src="./../../../assets/sidham-logo.png" alt="" height="256px" width="256px" class="img-fluid rounded-4">

        </div>
      </div>
      <div class="col-12 col-sm-7">
        <div class="fw-bolder mb-3">Dear valued customers,</div>
        <div class="catamaran-regular mb-3">
          Welcome to our car dealership website! We are proud to offer a wide range of quality cars that cater to
          different customer needs. Our team of experts is committed to providing you with the best car buying
          experience, whether you're looking for a hatchback, sedan, SUV, or any other type of car.
        </div>
        <div class="catamaran-regular mb-3">
          At our dealership, we understand that buying a car can be a big decision, which is why we strive to make the
          process as seamless and stress-free as possible. From helping you choose the right car to arranging for
          financing and insurance, we are here to assist you every step of the way.
        </div>
        <div class="catamaran-regular mb-3">
          We also understand the importance of safety and quality, which is why we offer only the best cars from reputed
          brands. All our cars undergo rigorous inspections to ensure that they meet our high standards of quality and
          safety.
        </div>
        <div class="catamaran-regular mb-3">
          We believe that our success is driven by the satisfaction of our customers, which is why we are committed to
          providing you with the best customer service possible. Our goal is to exceed your expectations and earn your
          trust and loyalty.
        </div>
        <div class="catamaran-regular mb-3">
          Thank you for considering our dealership for your car buying needs. We look forward to serving you soon!
        </div>
        <div>
          Sincerely,<br>
          Pranav Bansal
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Director Message End -->