<div class="container-fluid" style="background-color: rgb(31, 31, 31)">
  <div class="row text-white px-2 py-2 px-sm-3 py-sm-4">
    <div class="col-12 col-sm-6 d-flex flex-column align-items-center align-items-sm-start">
      <span class="text-center text-sm-start">© 2022 Copyright by Sidham Motors</span>
      <span>
        All Right Reserved;
        <a href="./privacy_policy.html">Privacy Policy</a>
      </span>
    </div>
    <div class="col-12 col-sm-6 d-flex flex-column align-items-center align-items-sm-end">
      <span>
        Developed By
        <a href="https://auribises.com/" class="text-muted" target="_blank">
          Auribises Technologies
        </a>
      </span>
    </div>
  </div>
</div>