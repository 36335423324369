<nav class="navbar fixed-top navbar-expand-lg navbar-dark" id="nav1" [ngClass]="{
    'initial-navbar': !enableNavbarStyle,
    'later-navbar': enableNavbarStyle
  }">
  <div class="container-fluid d-flex flex-row justify-content-between align-items-center py-1 px-5">
    <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="active">
      <img src="../../../assets/sidham-logo-white.png" class="img-fluid" style="height: 60px" alt="" />
    </a>
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup" [ngbCollapse]="isMenuCollapsed"
      [ngStyle]="{
        'background-color': !isMenuCollapsed ? '#000' : ''
      }">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link px-3 fs-6" [routerLink]="['/home']" routerLinkActive="active">HOME</a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-3 fs-6" [routerLink]="['/about']" routerLinkActive="active">ABOUT US</a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-3 fs-6" [routerLink]="['/cars']" routerLinkActive="active">CARS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-3 fs-6" [routerLink]="['/team']" routerLinkActive="active">TEAM</a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-3 fs-6" [routerLink]="['/outlet']" routerLinkActive="active">OUTLET
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-3 fs-6" [routerLink]="['/gallery']" routerLinkActive="active">GALLERY</a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-3 fs-6" [routerLink]="['/office']" routerLinkActive="active">OFFICE</a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-3 fs-6" [routerLink]="['/contact']" routerLinkActive="active">CONTACT US</a>
        </li>
      </ul>
    </div>
  </div>
</nav>