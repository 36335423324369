import { Component, OnInit,  } from '@angular/core';
import { outlets } from 'src/data';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss']
})
export class OutletComponent implements OnInit{
  outlets = outlets

  constructor(private dom: DomSanitizer){}

  ngOnInit(){
  }

  getSafeURL(url: string){
    return this.dom.bypassSecurityTrustResourceUrl(url)
  }
  
}
