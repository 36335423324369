import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent {
  queryForm: FormGroup = new FormGroup({});
  formSubmitted: boolean = false;

  mapLink = this.getSafeURL("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d881823.5820625599!2d75.90185300000002!3d30.304782!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391732934fb7f613%3A0x3c3311a752bad97e!2sTata%20Motors%20Cars%20Showroom%20-%20Sidham%20Motors%2C%20Bathinda!5e0!3m2!1sen!2sin!4v1679642034726!5m2!1sen!2sin");

  constructor(
    private fb: FormBuilder,
    private dom: DomSanitizer,
  ) {}

  getMapLink(url: string) {
    // return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getSafeURL(url: string){
    return this.dom.bypassSecurityTrustResourceUrl(url)
  }

  ngOnInit() {
    // this.collectionRef = this.dbService.getCollectionRef(QUERIES_COLLECTION);
    this.initForm();
  }

  initForm() {
    this.queryForm = this.fb.group({
      queryId: [""],
      name: ["", Validators.required],
      phone: ["", Validators.required],
      email: ["", Validators.required],
      query: ["", Validators.required],
      date: [new Date()]
    });
  }

  async onSubmit(event: any) {
    // event.preventDefault();
    // let values = { ...this.queryForm.value };

    // let docRef = doc(this.collectionRef, values.queryId);
    // setDoc(docRef, { ...values }, { merge: true })
    //   .then(() => {
    //     this.initForm();
    //     this.toast.success("Query Submitted", "Success");
    //   }, (error) => {
    //     this.initForm();
    //     this.toast.error("Unable to submit the form", "Error")
    //   })
    // // await this.dbService.sendQuery(this.queryForm);
    // // this.initForm();
  }
}
