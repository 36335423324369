import { Component } from '@angular/core';
import { carsPage } from 'src/data';

@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.scss']
})
export class CarsComponent {
  carsData = carsPage.cars
}
