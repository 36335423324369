<!--  -->
<div class="container-fluid background"></div>
<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <h2 class="catamaran-semiBold">{{ car.brand }} {{ car.name }}</h2>
            <div class="d-flex gap-2 sticky-top">
                <a type="button" class="btn btn-sm btn-outline-dark" routerLink="." [replaceUrl]="true" fragment="keyFeatures">Key Features</a>
                <a type="button" class="btn btn-sm btn-outline-dark" routerLink="." [replaceUrl]="true" fragment="gallery">Gallery</a>
            </div>
        </div>
    </div>
    <div class="row mt-4" id="keyFeatures">
        <div class="col-12 my-3">
            <h3 class="catamaran-semiBold">Key Features</h3>
        </div>
        <div class="col-12 col-sm-4 mb-3" *ngFor="let feature of car.features; index as idx">
            <div class="card rounded-4 h-100 border-opacity-10 shadow-sm p-3">
                <img [src]="feature.image" [alt]="feature.title" loading="eager" class="card-img-top rounded-4" height="200px" (error)="errorHandler($event)">
                <div class="card-body mt-2 px-0">
                    <h5 class="card-title catamaran-semiBold">{{ feature.title }}</h5>
                    <span class="card-text catamaran-regular">{{ feature.tagLine }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4" id="gallery">
        <div class="col-12 my-3">
            <h3 class="catamaran-semiBold">Gallery</h3>
        </div>
        <div class="col-12 col-sm-4 mb-3" *ngFor="let image of car.images; index as idx">
            <a [href]="image" target="_blank" [id]="'image-'+idx" class="shadow-sm">
                <img [src]="image" [alt]="image" loading="eager" (error)="errorHandler($event)" class="img-fluid rounded-4">
            </a>
        </div>
    </div>
</div>