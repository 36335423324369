import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { CarDetailsComponent } from './pages/car-details/car-details.component';
import { CarsComponent } from './pages/cars/cars.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { GroupInfoComponent } from './pages/group-info/group-info.component';
import { HomeComponent } from './pages/home/home.component';
import { OutletComponent } from './pages/outlet/outlet.component';

const routes: Routes = [
  // { path: '', component: HomeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'cars/:car', component: CarDetailsComponent },
  { path: 'cars', component: CarsComponent },
  { path: 'team', component: GroupInfoComponent },
  { path: 'outlet', component: OutletComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'office', component: GalleryComponent },
  { path: 'contact', component: ContactusComponent },
  { path: 'about', component: AboutUsComponent },
  { path: '**', redirectTo: "/home" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    anchorScrolling: "enabled",
    scrollOffset: [0, 94]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
