<div class="container-fluid background"></div>
<div class="container my-5 ">
  <div class="row">
    <div class="col-12 col-sm-5 mb-3 mb-lg-0">
      <img src="./../../../assets/aboutUs/banner2.jpg" alt="" class="img-fluid rounded-4 h-100 d-none d-lg-block" style="
        object-position: 10% 20%;
        object-fit: cover;
      ">
      <img src="./../../../assets/aboutUs/banner2.jpg" alt="" class="img-fluid rounded-4 shado d-block d-lg-none" style="
        object-position: 10% 20%;
        object-fit: cover;
      ">
    </div>
    <div class="col-12 col-sm-7">
      <h2 class="catamaran-semiBold">About Us</h2>
      <p class="catamaran-regular text-justify">
        <strong>Sidham Motors</strong> was incorporated in 2008-2009, where we pride ourselves on offering a
        premium car buying experience to our
        customers. Authorised Suzuki Two wheeler dealer till 2021 through old dirctors. Taken Over by Pranav Bansal &
        Krishan Bansal as Directors in September 2022 and all old directors resigned</p>

      <p class="catamaran-regular text-justify">At <strong>Sidham Motors</strong>, we understand that purchasing a car
        is a
        significant decision and investment. That's why we strive to provide our customers with exceptional service,
        top-quality vehicles, and competitive pricing. Our knowledgeable and experienced sales team will work with you
        to find the right vehicle that fits your needs and budget. We offer a wide selection of new and pre-owned
        vehicles from various makes and models. Whether you're looking for a fuel-efficient sedan, a rugged SUV, or a
        luxury car, we have something for everyone. We also offer a range of financing options to help make your dream
        car a reality.
      </p>

      <p class="catamaran-regular text-justify">
        Our commitment to our customers doesn't end when you drive off the lot. Our state-of-the-art service center is
        staffed with certified technicians who can handle any maintenance or repair needs your vehicle may require. We
        also carry a full line of genuine OEM parts and accessories to ensure your vehicle runs smoothly and stays
        looking great. At <strong>Sidham Motors</strong>, we value honesty, integrity, and transparency. We believe in
        building
        long-lasting relationships with our customers and the community. Come visit us today and experience the
        difference for yourself!
      </p>
    </div>
  </div>
</div>

<div class="container-fluid bg-light my-5 py-5">
  <div class="container">
    <div class="row">
      <div class="col">
        <h2 class="text-center catamaran-semiBold">Our Mission</h2>
        <p class="text-center catamaran-regular">
          At Sidham Motors, our mission is to provide our customers with an exceptional car buying experience, from
          start to finish. We are committed to offering a wide selection of quality vehicles, competitive pricing,
          flexible financing options, and expert service that goes above and beyond. We believe that honesty, integrity,
          and transparency are the keys to building lasting relationships with our customers and the community, and we
          strive to uphold these values in everything we do.
        </p>
        <!-- <h5 class="text-center text-sm catamaran-regular w-75 mx-auto">
          "Our commitment to quality, transparency, and customer satisfaction sets us apart from the competition, making
          Sidham Motors the go-to destination for anyone looking to buy, sell, or service a vehicle."
        </h5> -->
      </div>
    </div>
    <!-- <div class="row mt-4">
      <div class="col">
        <p class="catamaran-regular text-justify">
          At Sidham Motors, our mission is to provide our customers with the best car buying and service
          experience possible. We understand that buying a car can be a stressful and overwhelming process, which is
          why we
          strive to make it as easy and enjoyable as possible. Our team of experts is dedicated to helping you every
          step of the way, from finding the perfect vehicle to securing financing and maintaining your vehicle for
          years to come.
        </p>
        <p class="catamaran-regular text-justify">
          We believe that transparency and honesty are the keys to building trust and loyalty with our customers,
          which is why we are committed to providing upfront pricing and a no-pressure sales environment. Our team
          is knowledgeable about all of the latest automotive technology and trends, and we are always eager to
          share our expertise with our customers.
        </p>
        <p class="catamaran-regular text-justify">
          In addition to our sales department, we also have a state-of-the-art service workshop, staffed with
          certified technicians who are trained to handle all types of vehicle maintenance and repairs. Our service
          team is committed to using the latest technology and tools to keep your vehicle running smoothly, and we
          only use genuine OEM parts to ensure the highest quality and reliability.
        </p>
      </div>
    </div> -->
  </div>
</div>

<div class="container my-5">
  <div class="row">
    <div class="col">
      <h2 class="text-center catamaran-semiBold">Our Vission</h2>
      <p class="text-center catamaran-regular">
        Our vision is to become the leading car dealership in the region, known for our exceptional customer service,
        outstanding selection of vehicles, and commitment to quality and value. We aim to set the industry standard for
        excellence, innovation, and sustainability, by constantly evolving our business practices and embracing new
        technologies and trends. We believe that by putting our customers first, and focusing on their needs and
        preferences, we can create a car buying experience that is truly second to none.
      </p>
    </div>
  </div>
</div>